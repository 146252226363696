import React, { useState } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import EnsRadioInput from "../EnsRadioInput/EnsRadioInput"
import InfoIcon from "../InfoIcon/InfoIcon"

const EnsRadioGroup = ({ name, options, selectedOption , setSelectedOption ,classes}) => {
  return (
    <div className="row" style={{ justifyContent: "center" }}>
      {options.map((option, index) => (
        <div
          className={name !== "timeOfDay" ? "col-md-6" : "col-md-4"}
          key={option.value}
          style={{ position: "relative" }}
        >
          {option.details && (
            <div
              style={{
                position: "absolute",
                right: "65px",
                top: "50px",
                zIndex: 1,
              }}
            >
              <InfoIcon
                customStyle="blue-info-btn"
                infoMessage={option.details}
                imgColor="blue"
                isToolTip={false}
                tooltipPosition="left-start"
              />
            </div>
          )}
          <Field
            name={name}
            variant="radio"
            component={EnsRadioInput}
            label={option.label}
            value={option.value}
            icon={option.icon}
            id={index}
            item={option}
            className={`${selectedOption === index ? "selected" : ""} ${classes}`}
            onClick={() => selectedOption !== index && setSelectedOption(index , name)}
          />
        </div>
      ))}
    </div>
  )
}

EnsRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default EnsRadioGroup
