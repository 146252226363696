import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Field, FormikProvider, useFormik } from "formik"
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"
import FinalizeImage from "../../assets/images/jpg/finalize-form.png"
import EnsInput from "../EnsInput/EnsInput"
import EnsButton from "../EnsButton/EnsButton"
import "./ConfiguratorFinalizeForm.scss"
import Map from "../Map/Map"
import { counties } from "./Counties"
import { useTranslation } from "react-i18next"
import ValidationSchema from "./validationSchema"

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

const ConfiguratorFinalizeForm = ({
  handleSubmit,
  configuratorType,
  setConfiguratorType,
}) => {
  const [emailError, setEmailError] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const { t } = useTranslation();
  const validationSchema = ValidationSchema()

  const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      typeOfContact: "persoana fizica",
      fullAddress: "",
      latitude: "",
      longitude: "",
      county: "",
      newsLetter: false,
      recaptcha: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {
      setConfiguratorType(true)
      handleSubmit(formik.values)
    },
  })

  const callCheckEmailDebounced = () => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 6) {
      axios
        .post("/api/validate-email", {
          source: "electricUp",
          email: debouncedSearchTerm,
        })
        .then(response => {
          setEmailError(t("Email invalid"))
          if (response && response.data && response.data.result) {
            if (
              response.data.result.verdict === "Valid" ||
              response.data.result.verdict === "Risky"
            ) {
              formik.setFieldError("email", null)
              setEmailError("")
            }
          }
        })
        .catch(err => console.log("email validation error-->", err))
    }
  }

  useEffect(() => {
    setSearchTerm(formik.values.email)
  }, [formik.values.email])

  // Effect for API call
  useEffect(
    () => {
      callCheckEmailDebounced()
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )

  const chooseMessage = coordinates => {
    formik.setFieldValue("latitude", coordinates.lat)
    formik.setFieldValue("longitude", coordinates.lng)
    formik.setFieldValue("fullAddress", coordinates.address)
  }

  return (
    <div className="configurator-finalize-form">
      <div className="container">
        <div className="col-md-6 form-col">
          <h1> {t("Detalii de contact")} </h1>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Field
                name="lastName"
                variant="text"
                label={t("Nume de familie")}
                component={EnsInput}
                id={1}
              />
              <Field
                name="firstName"
                variant="text"
                label={t("Prenume")}
                component={EnsInput}
                id={2}
              />
              <Field
                name="email"
                variant="email"
                label={t("Adresa de email")}
                component={EnsInput}
                id={3}
              />
              {emailError && formik.values.email && (
                <p
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    marginTop: "-2rem",
                  }}
                >
                  {emailError}
                </p>
              )}
              <Field
                name="phone"
                variant="tel"
                label={t("Telefon mobil")}
                component={EnsInput}
                id={4}
              />
              <p>
                {t("Pentru o ofertă mai relevantă, completați și următoarele informații:")}
              </p>
              {formik.errors.fullAddress && (
                <p style={{ color: "red", fontStyle: "italic" }}>
                  {formik.errors.fullAddress}
                </p>
              )}
              <div className="optional-fields">
                <div style={{ marginBottom: "6rem", marginTop: "10rem" }}>
                  <Map
                    chooseMessage={chooseMessage}
                    center={{
                      lat: 47.06292759999999,
                      lng: 21.9331372,
                    }}
                    height="400px"
                    zoom={15}
                  />
                </div>
                <Field
                  style={{ display: "none" }}
                  name="latitude"
                  as="input"
                  label="latitude"
                  placeholder={t("Latitudine")}
                  id={6}
                />
                <Field
                  style={{ display: "none" }}
                  name="longitude"
                  variant="text"
                  label="longitude"
                  placeholder={t("Longitudine")}
                  id={7}
                />
                <Field
                  style={{ display: "none" }}
                  name="fullAddress"
                  variant="text"
                  component={EnsInput}
                  className="full-width"
                  id={6}
                />
                <Field
                  name="county"
                  variant="select"
                  label={t("Alege județul")}
                  options={counties}
                  component={EnsInput}
                  className="county-input"
                  id={7}
                />
                <br></br>
                <p>{t("Tip persoană")}:</p>
                <div
                  className="custom-control"
                  style={{ marginBottom: "3rem" }}
                >
                  <Field
                    component="div"
                    name="typeOfContact"
                    className="radioInputs"
                  >
                    <div>
                      <input
                        type="radio"
                        id="persFizica"
                        name="typeOfContact"
                        value="persoana fizica"
                        defaultChecked={
                          formik.values.typeOfContact === "persoana fizica"
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="persFizica"
                      >
                        {t("Persoană fizică")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="PersJuridica"
                        name="typeOfContact"
                        value="persoana juridica"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="PersJuridica"
                      >
                        {t("Persoană judirică")}
                      </label>
                    </div>
                  </Field>
                </div>
                <Field
                  name="newsLetter"
                  variant="checkbox-classic"
                  label={t("Sunt de acord să primesc informații despre sistemele Ensys")}
                  component={EnsInput}
                  id={8}
                />
              </div>
              <ReCAPTCHA
                name="recaptcha"
                sitekey="6LeRlA0oAAAAANQZT1gzZcmdilFY-XV4974Pkz37"
                onChange={response => {
                  formik.setFieldValue("recaptcha", response)
                }}
                className="recaptcha-validation"
              />
              {formik.errors.recaptcha && formik.touched.recaptcha && (
                <p style={{ color: "red", fontStyle: "italic" }}>
                  {formik.errors.recaptcha}
                </p>
              )}

              <EnsButton
                variant="primary"
                type="submit"
                className="form-button"
                disabled={!!emailError}
              >
                {t("Finalizează")}
              </EnsButton>
            </form>
          </FormikProvider>
        </div>
      </div>
      <img className="finalize-img" src={FinalizeImage} alt="ensys contact" />
    </div>
  )
}

ConfiguratorFinalizeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}
export default ConfiguratorFinalizeForm
