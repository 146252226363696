import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

export const useHomePaeFAQsSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n

  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Homepage" } }
        language: { code: { eq: RO } }
      ) {
        homePageFields {
          faqsSection {
            title
            questionsToShow {
              question {
                ... on WpFAQ {
                  id
                  faqFields {
                    answer
                    question
                  }
                }
              }
            }
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Homepage" } }
        language: { code: { eq: EN } }
      ) {
        homePageFields {
          faqsSection {
            title
            questionsToShow {
              question {
                ... on WpFAQ {
                  id
                  faqFields {
                    answer
                    question
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const page = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    page[language]?.homePageFields?.faqsSection ||
    wpPageRo?.homePageFields?.faqsSection
  )
}
