import React from "react"
import propTypes from "prop-types"
import CookiesBanner from "../CookiesBanner/CookiesBanner"
import "@fontsource/ruda"

import "../../sass/style.scss"
import "./LayoutConfiguratorPage.scss"

const Layout = ({ children }) => {
  return (
    <>
      <main id="main">
        <div className="main-div">
          {children}
        </div>
      </main>
      <CookiesBanner />
    </>
  )
}

Layout.defaultProps = {
  classes: null,
  children: null,
}

Layout.propTypes = {
  classes: propTypes.string,
  children: propTypes.node,
}

export default Layout
