import * as yup from "yup";
import { useTranslation } from "react-i18next";

const ValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    lastName: yup
      .string()
      .max(32, t("trebuie să fie de maxim 32 caractere"))
      .required(t("Vă rugăm introduceți numele de familie")),
    firstName: yup
      .string()
      .max(32, t("trebuie să fie de maxim 32 caractere"))
      .required(t("Vă rugăm introduceți prenumele")),
    email: yup
      .string()
      .max(62, t("trebuie să fie de maxim 62 caractere"))
      .email(t("Introduceți o adresă de email validă"))
      .required(t("Vă rugăm introduceți adresa de email")),
    phone: yup
      .string()
      .max(16, t("trebuie să fie de maxim 16 caractere"))
      .required(t("Vă rugăm introduceți numărul de telefon")),
    fullAddress: yup
      .string()
      .max(255, t("trebuie să fie de maxim 255 caractere"))
      .required(t("Introduceți adresa sau folosiți harta")),
    county: yup.object().required(t("Selectează un județ")),
    recaptcha: yup.string().nullable().required(t("Vă rugăm validați verificarea")),
  });

  return validationSchema;
};

export default ValidationSchema;