/* eslint-disable no-nested-ternary */
import React from "react"
import PropTypes from "prop-types"
import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import EnsInput from "../EnsInput/EnsInput"
import EnsButton from "../EnsButton/EnsButton"

import "./ChooseConfigType.scss"

const ChooseConfigType = ({ setConfiguratorType }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      ConfigType: {},
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      ConfigType: Yup.object().required(
        t("Vã rugãm selectați tipul proiectului!")
      ),
    }),
    onSubmit: values => setConfiguratorType(values.ConfigType.value),
  })

  return (
    <div className="ensys-choose-config-type">
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <h1>{t("Vã rugãm sã selectați tipul proiectului:")}</h1>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Field
                  name="ConfigType"
                  variant="select"
                  label={t("Tipul Proiectului")}
                  component={EnsInput}
                  options={[
                    { label: t("Rezidențial"), value: "Rezidential Sumar" },
                    { label: t("Public"), value: "Public Sumar" },
                    { label: t("Industrial"), value: "Industrial Sumar" },
                    { label: t("Agro"), value: "Agro Sumar" },
                    { label: t("Comercial"), value: "Comercial Sumar" },
                  ]}
                  className="full-width"
                  id="select_type"
                />
                <EnsButton
                  variant="primary"
                  type="submit"
                  className="form-button"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {t("Începe")}
                </EnsButton>
              </form>
            </FormikProvider>
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    </div>
  )
}

ChooseConfigType.propTypes = {
  setConfiguratorType: PropTypes.func.isRequired,
}
export default ChooseConfigType
