import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHomePaeFAQsSection } from "../../hooks/HomePage/useHomePaeFAQsSection"
import EnsButton from "../EnsButton/EnsButton"
import FaqCollapsible from "../FaqCollapsible/FaqCollapsible"
import "./FaqSection.scss"
import EnsLink from "../EnsLink/EnsLink"

const FaqSection = ({ isSimple, className }) => {
  const [update, setUpdate] = useState(false)

  const { t } = useTranslation()

  const { title, questionsToShow } = useHomePaeFAQsSection()

  const queryString = typeof window !== "undefined" ? window.location.hash : ""

  const getQuestionId = string => {
    return string
      .replace(/([^A-Za-z0-9[\]{} _.:-])?/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
  }

  const hasNullQuestions = questionsToShow && questionsToShow.length && questionsToShow.some(q => q.question === null);

  if (!hasNullQuestions) {
    return (
      <section
        className={`faq-section ${isSimple && "is-simple"} ${className || ""}`}
      >
        <div className="container">
          {!isSimple && title && (
            <div
              className="title-content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
          <div className="faq-list">
            <table className="table">
              <tbody>
                {questionsToShow &&
                  questionsToShow.length &&
                  questionsToShow.map(q => {
                    const { question } = q
                    return (
                      <FaqCollapsible
                        key={`qq-${question.id}`}
                        id={getQuestionId(question.id)}
                        queryString={queryString.replace("#", "")}
                        setUpdate={setUpdate}
                        update={update}
                        title={question.faqFields?.question}
                        copy={question.faqFields?.answer}
                      />
                    )
                  })}
              </tbody>
            </table>
          </div>
          <EnsLink link={{ url: "/faqs" }}>
            <EnsButton className="view-more-btn" variant="ghost-link">
              {t("Vezi mai mult")}
            </EnsButton>
          </EnsLink>
        </div>
      </section>
    )
  } else {
    return ''
  }
}

FaqSection.defaultProps = { isSimple: false }
FaqSection.propTypes = { isSimple: PropTypes.bool }

export default FaqSection
