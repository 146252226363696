/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import PropTypes from "prop-types"
import { useStore } from "../../hooks/Configurator/states"
import formConfig from "../../hooks/Configurator/formConfig"
import "./ConfiguratorFooter.scss"
import { useTranslation } from "react-i18next"

const ConfiguratorFooter = ({ configuratorType }) => {
  // Store
  const formStore = useStore(state => state.formStore)
  const { t } = useTranslation();
  formStore.btnLabel = t("Următorul pas");
  // Actions
  const setFormStore = useStore(state => state.setFormStore)

  const formConfigs = formConfig(configuratorType)

  const checkIfWasAnswered = uid => {
    const question = formConfigs.find(item => item.uid === uid)
    if (!formStore.values || !Object.keys(formStore.values).length) return false
    if (question.type === "connected_inputs")
      return (
        formStore.values[question.inputs[0].name] !== undefined ||
        formStore.values[question.inputs[1].name] !== undefined
      )
    if (question.type === "radio") {
      if (formStore.values[question.name] === undefined) return false
      // check if selected option has a select input in it
      const selectedOption = question.options.find(
        option => option.value === formStore.values[question.name]
      )

      if (selectedOption && selectedOption.type === "select")
        return formStore.values[`${question.name}Selected`] !== undefined

      return true
    }
    return formStore.values[question.name] !== undefined
  }

  const navigateToQuestion = uid => {
    const nextQuestionUid = uid
    const nextQuestion = formConfigs.find(item => item.uid === nextQuestionUid)
    setFormStore({
      ...formStore,
      btnLabel: t("Următorul pas"),
      currentQuestion: nextQuestionUid,
      currentItem: nextQuestion,
    })
  }

  return (
    <div className="configurator-footer">
      {formConfigs.map(q => (
        <div
          className="footer-item"
          key={q.uid}
          onClick={() => navigateToQuestion(q.uid)}
          role="button"
          tabIndex={-1}
        >
          <div>
            {/* <p
              className={`${
                q.uid === formStore.currentQuestion && "is-current"
              }`}
            >
              {q.summaryTitle}
            </p> */}

            <div
              className={`line ${q.uid === formStore.currentQuestion && "is-current"
                } ${checkIfWasAnswered(q.uid) && "is-answered"}`}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

ConfiguratorFooter.propTypes = {
  configuratorType: PropTypes.string.isRequired,
}
export default ConfiguratorFooter
