/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import PropTypes from "prop-types"
import "./EnsYesNoInput.scss"
import EnsRadioGroup from "../EnsRadioGroup/EnsRadioGroup"
import { useTranslation } from "react-i18next"

const EnsYesNoInput = ({ name, selectedOption, setSelectedOption }) => {
  const { t } = useTranslation();

  return (
    <div className="yes-no-input-wrapper">
      <EnsRadioGroup
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        name={name}
        classes = "yes-no-input"
        options={[
          { label: t("Da"), value: t("Da") },
          { label: t("Nu"), value: t("Nu") },
        ]}
      />
    </div>
  )
}

EnsYesNoInput.defaultProps = {
  name: null,
}

EnsYesNoInput.propTypes = {
  name: PropTypes.string,
}

export default EnsYesNoInput
