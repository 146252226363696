import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

const FaqCollapsible = ({ open, title, copy, setUpdate, id, queryString }) => {
  const [isOpen, setIsOpen] = useState(open)

  const handleFilterOpening = () => {
    setIsOpen(prev => !prev)
    setUpdate(prev => !prev)
    if (typeof window !== "undefined") {
      if (isOpen) {
        window.history.pushState(null, null, " ")
      } else {
        window.history.pushState(null, null, `#${id}`)
      }
    }
  }

  useEffect(() => {
    if (queryString === id) {
      handleFilterOpening()
    }
  }, [])

  return (
    <>
      <tr>
        <td className="fake-td">
          <div id={id} className="fake-anchor" />
        </td>
      </tr>
      <tr
        className={`slide-the-bottom ${isOpen ? "active" : ""}`}
        onClick={handleFilterOpening}
        onKeyDown={handleFilterOpening}
        role="button"
      >
        <td colSpan="2">
          <div className="heading-wrapper">
            <span className="heading">{title}</span>{" "}
            <span className="faq-icon">{`${isOpen ? "-" : "+"}`}</span>
          </div>
        </td>
      </tr>
      <tr className={`slide-from-top ${isOpen ? "active" : ""}`}>
        <td colSpan="2" className="content-wrapper content">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: copy }} />
        </td>
      </tr>
    </>
  );
  
}

export default FaqCollapsible

FaqCollapsible.defaultProps = {
  open: null,
  id: null,
}

FaqCollapsible.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  setUpdate: PropTypes.func.isRequired,
  id: PropTypes.string,
  queryString: PropTypes.string.isRequired,
}
