import { useTranslation } from "react-i18next"
import * as yup from "yup"

const validationSchema = ({ type, name, label }) => {
  const { t } = useTranslation();

  const errorMessages = {
    mandatory: t("obligatoriu"),
    isMandatory: t("este obligatoriu"),
    pleaseEnter: t("Vă rugăm introduceți"),
    validEmail: t("trebuie să fie o adresă de email validă"),
    selectAnOptionFor: t("Vă rugăm alegeți o opțiune pentru"),

  }

  const validationObj = {
    text: yup
      .string(`${errorMessages.pleaseEnter} ${label}`)
      .max(32, t("trebuie să fie de maxim 32 caractere"))
      .required(`${label} ${errorMessages.mandatory}`),
    email: yup
      .string(`${errorMessages.pleaseEnter} ${label}`)
      .max(62, t("Emailul trebuie să fie de maxim 62 caractere"))
      .email(`${label} ${errorMessages.validEmail}`)
      .required(`${label} ${errorMessages.isMandatory}`),
    radio: yup
      .string(`${errorMessages.selectAnOptionFor}  ${label}`)
      .required(t("o opțiune este obligatorie")),
    checkbox: yup
      .array()
      .min(1, t("Alegeți cel puțin o opțiune"))
      .nullable()
      .required(`${label} ${errorMessages.mandatory}`),
  }

  return yup.object().shape({
    [name]: validationObj[type],
  })
}

export default validationSchema
