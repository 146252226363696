import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import { graphql } from "gatsby"
import LayoutConfiguratorPage from "../components/LayoutConfiguratorPage/LayoutConfiguratorPage"
import Header from "../components/Header/Header"
import Configurator from "../components/Configurator/Configurator"
import ChooseConfigType from "../components/ChooseConfigType/ChooseConfigType"
import Footer from "../components/Footer/Footer"
import FaqSection from "../components/FaqSection/FaqSection"
import "react-toastify/dist/ReactToastify.css"

const ConfiguratorPage = ({ location }) => {
  const [configuratorType, setConfiguratorType] = useState("")
  const [choose, setChoose] = useState(true)

  useEffect(() => {
    if (location.state)
      if (location.state.prevPath) {
        if (location.state.prevPath === "/sistem-fotovoltaic-rezidential/") {
          setConfiguratorType("Rezidential Sumar")
          setChoose(false)
        } else if (
          location.state.prevPath === "/panouri-fotovoltaice-public/"
        ) {
          setConfiguratorType("Public Sumar")
          setChoose(false)
        } else if (location.state.prevPath === "/panouri-solare-industrial/") {
          setConfiguratorType("Industrial Sumar")
          setChoose(false)
        } else if (location.state.prevPath === "/panouri-solare-agro/") {
          setConfiguratorType("Agro Sumar")
          setChoose(false)
        } else if (
          location.state.prevPath === "/panouri-fotovoltaice-comercial/"
        ) {
          setConfiguratorType("Comercial Sumar")
          setChoose(false)
        }
      }
  }, [])

  const startForm = configType => {
    if (configType) {
      setConfiguratorType(configType)
      setChoose(false)
    }
  }

  if (choose)
    return (
      <LayoutConfiguratorPage>
        <Header lightMode />
        <ChooseConfigType setConfiguratorType={startForm} />
        <FaqSection className="custom-background-faq-section" />
        <Footer />
      </LayoutConfiguratorPage>
    )

  return (
    <LayoutConfiguratorPage>
      <Header configuratorType={configuratorType} lightMode />
      <Configurator
        setConfiguratorType={setConfiguratorType}
        configuratorType={configuratorType}
      />
      <Footer />
      <ToastContainer />
    </LayoutConfiguratorPage>
  )
}

ConfiguratorPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ prevPath: PropTypes.string }),
  }).isRequired,
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ConfiguratorPage
