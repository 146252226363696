import React from "react"
import FinalizeImage from "../../assets/images/jpg/finalize-form.png"
import { useStore } from "../../hooks/Configurator/states"
import EnsButton from "../EnsButton/EnsButton"
import EnsLink from "../EnsLink/EnsLink"
import FaqSection from "../FaqSection/FaqSection"
import WatsappIcon from "../../assets/images/webp/whatsapp-click.webp"
import "./ThankYouForSubmitting.scss"
import { useTranslation } from "react-i18next"

const ThankYouForSubmitting = () => {
  const { t } = useTranslation()
  const formStore = useStore(state => state.formStore)
  const thankYouMsg = t("Mulțumim pentru informații")

  return (
    <div className="thank-you-for-submitting">
      <div className="container">
        <div className="col-md-6">
          <h1>{`${thankYouMsg}, ${formStore?.values?.firstName}!`}</h1>
          <p>
            {t(
              "O persoană din departamentul Vânzări va lua legătura cu tine pentru a stabii pașii următori"
            )}
          </p>
          <div className="redirect-buttons">
            <EnsLink link={{ url: "/" }}>
              <EnsButton variant="secondary">
                {t("Întoarce-te pe Homepage")}
              </EnsButton>
            </EnsLink>
            <a href="https://wa.me/40774016324">
              <img className="watshapp-img" src={WatsappIcon} alt="undefined" />
            </a>
          </div>
          <FaqSection isSimple />
        </div>
      </div>
      <img className="finalize-img" src={FinalizeImage} alt="ensys contact" />
    </div>
  )
}

export default ThankYouForSubmitting
