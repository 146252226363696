/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { uniq } from "lodash"
import { Field, FormikProvider, useFormik } from "formik"
import { useStore } from "../../hooks/Configurator/states"
import validationSchema from "./validationSchema"
import EnsInput from "../EnsInput/EnsInput"
import EnsRadioGroup from "../EnsRadioGroup/EnsRadioGroup"
import EnsButton from "../EnsButton/EnsButton"
import EnsCheckboxGroup from "../EnsCheckboxGroup/EnsCheckboxGroup"
import EnsConnectedInputs from "../EnsConnectedInputs/EnsConnectedInputs"
import "./ConfiguratorForm.scss"
import EnsYesNoInput from "../EnsYesNoInput/EnsYesNoInput"
import InfoIcon from "../InfoIcon/InfoIcon"
import { useTranslation } from "react-i18next"

const ConfiguratorForm = ({ handleSubmit, formItem, btnLabel }) => {
  // Store
  const formStore = useStore(state => state.formStore)
  const [checkboxSelectedOptions, setCheckboxSelectedOptions] = useState({});

  const addChekboxOptions = (selectedOption, name) => {
    setCheckboxSelectedOptions((prevCheckboxSelectedOptions) => {
      return {
        ...prevCheckboxSelectedOptions,
        [name]: selectedOption,
      };
    });
  }

  const { t } = useTranslation();
  formStore.btnLabel = t("Următorul pas");

  const getInitialValues = useMemo(() => {
    if (!Object.keys(formStore.values).length) return { [formItem.name]: "" }

    if (formItem.type === "checkbox") {
      return {
        [formItem.name]: uniq([
          ...formItem.options.filter(o => o.mandatory).map(o => o.value),
          ...(formStore.values && formStore.values[formItem.name] !== undefined
            ? formStore.values[formItem.name]
            : []),
        ]),
      }
    }
    if (formItem.type === "connected_inputs")
      return {
        [formItem.inputs[0].name]: formStore.values[formItem.inputs[0].name],
        [formItem.inputs[1].name]: formStore.values[formItem.inputs[1].name],
      }

    return { [formItem.name]: formStore.values[formItem.name] }
  }, [formStore, formItem])

  const formik = useFormik({
    initialValues: getInitialValues,
    enableReinitialize: true,
    validationSchema: validationSchema({
      label: formItem.label,
      name: formItem.name,
      type: formItem.type,
    }),
    onSubmit: () => handleSubmit(formik.values),
  })
  return (
    <div className="configurator-form">
      <FormikProvider value={formik}>
        <div className="row">
          <div className="col-md-8">
            <h2>{formItem.label}</h2>
          </div>
          {formItem.details && (
            <div
              className="col-md-3 d-flex flex-row-reverse"
              style={{ alignSelf: "center", justifyContent: "center" }}
            >
              <InfoIcon
                customStyle="blue-info-btn absolute-mobile"
                infoMessage={formItem.details}
                imgColor="blue"
                isToolTip={false}
                tooltipPosition="left-start"
              />
            </div>
          )}
          {formItem.infoMessage && (
            <div className="col-md-4">
              <img src={InfoIcon} className="info-icon" alt="ensys info" />
              <p className="info-message">{formItem.infoMessage}</p>
            </div>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          {formItem.type === "text" && (
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <Field
                  name={formItem.name}
                  id={formItem.uid}
                  label={formItem.label}
                  variant="text"
                  placeholder={formItem.label}
                  component={EnsInput}
                />
              </div>
              <div className="col-md-3" />
            </div>
          )}
          {formItem.type === "email" && (
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <Field
                  name={formItem.name}
                  id={formItem.uid}
                  label={formItem.label}
                  variant="email"
                  placeholder={formItem.label}
                  component={EnsInput}
                />
              </div>
              <div className="col-md-3" />
            </div>
          )}
          {formItem.type === "radio" && (
            <>
              <EnsRadioGroup
                name={formItem.name}
                options={formItem.options}
                selectedOption={checkboxSelectedOptions[formItem.name]}
                setSelectedOption={addChekboxOptions} />
              {formik.errors[formItem.name] && (
                <p className="form-error-radio">
                  <em>{formik.errors[formItem.name]}</em>
                </p>
              )}
            </>
          )}
          {formItem.type === "checkbox" && (
            <>
              <EnsCheckboxGroup
                name={formItem.name}
                options={formItem.options}
                values={formik.values[formItem.name]}
              />
              {formik.errors[formItem.name] && (
                <p className="form-error-checkbox">
                  <em>{formik.errors[formItem.name]}</em>
                </p>
              )}
            </>
          )}
          {formItem.type === "number_pers" && (
            <Field
              name={formItem.name}
              id={formItem.uid}
              label={formItem.label}
              variant="number_pers"
              placeholder={formItem.label}
              component={EnsInput}
            />
          )}
          {formItem.type === "connected_inputs" && (
            <EnsConnectedInputs item={formItem} form={formik} />
          )}
          {formItem.type === "number" && (
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <Field
                  name={formItem.name}
                  id={formItem.uid}
                  label={formItem.label}
                  variant="number"
                  placeholder={formItem.label}
                  component={EnsInput}
                />
              </div>
              <div className="col-md-3" />
            </div>
          )}
          {formItem.type === "select" && (
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <Field
                  name={formItem.name}
                  id={formItem.uid}
                  label={formItem.label}
                  variant="select"
                  placeholder={formItem.label}
                  options={formItem.options}
                  defaultValue={formStore.values[formItem.name] || null}
                  component={EnsInput}
                />
              </div>
              <div className="col-md-3" />
            </div>
          )}
          {formItem.type === "textarea" && (
            <div className="row mt-5">
              <div className="col-md-3" />
              <div className="col-md-6">
                <Field
                  name={formItem.name}
                  id={formItem.uid}
                  label={formItem.label}
                  variant="textarea"
                  component={EnsInput}
                />
              </div>
              <div className="col-md-3" />
            </div>
          )}
          {formItem.type === "yes_no" && (
            <div className="row mt-5">
              <div className="col-md-3" />
              <div className="col-md-6">
                <EnsYesNoInput 
                  name={formItem.name}
                  selectedOption={checkboxSelectedOptions[formItem.name]}
                  setSelectedOption={addChekboxOptions} 
                />
              </div>
              <div className="col-md-3" />
            </div>
          )}
          <EnsButton
            variant="primary"
            type="submit"
            className="form-button"
            disabled={!formik.isValid}
          >
            {btnLabel}
          </EnsButton>
        </form>
      </FormikProvider>
    </div>
  )
}

ConfiguratorForm.propTypes = {
  formItem: PropTypes.shape({
    name: PropTypes.string,
    uid: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array,
    infoMessage: PropTypes.string,
    inputs: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  btnLabel: PropTypes.string.isRequired,
}

export default ConfiguratorForm
