import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import EnsInput from "../EnsInput/EnsInput"
import "./EnsConnectedInputs.scss"
import { useTranslation } from "react-i18next"

const EnsConnectedInputs = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div className="connected-inputs-wrapper row">
      <div className="col-md-5">
        <Field
          name={item.inputs[0].name}
          variant="number"
          component={EnsInput}
          label={item.inputs[0].label}
          connected={{
            name: item.inputs[1].name,
            baseConversion: item.inputs[1].baseConversion,
          }}
        />
      </div>
      <div
        className="col-1 d-flex"
        align="center"
        style={{ justifyContent: "center" }}
      >
        <p style={{ alignSelf: "center" }}>{t("sau")}</p>
      </div>
      <div className="col-md-5">
        <Field
          name={item.inputs[1].name}
          variant="number"
          component={EnsInput}
          label={item.inputs[1].label}
          connected={{
            name: item.inputs[0].name,
            baseConversion: item.inputs[0].baseConversion,
          }}
        />
      </div>
    </div>
  )
}

EnsConnectedInputs.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    inputs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        baseConversion: PropTypes.number,
      })
    ),
  }).isRequired,
}

export default EnsConnectedInputs
