import React, { useState } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import EnsInput from "../EnsInput/EnsInput"
import "./EnsCheckboxGroup.scss"
import InfoIcon from "../InfoIcon/InfoIcon"

const EnsCheckboxGroup = ({ name, options, values }) => {
  const [selectedOption, setSelectedOption] = useState(-1)
  const hasImage = options.some(option => option.icon)

  const inters = options.filter(o => values?.includes(o.value) || o.mandatory)
  const image = inters[inters.length - 1]?.icon

  return (
    <div className="row checkbox-group">
      {hasImage && (
        <div className="col-md-6 image-container">
          {image ? <img src={image} alt="option" className="no-pointer-events"/> : null}
        </div>
      )}
      <div className={`${hasImage ? "col-md-6" : ""}`}>
        <div className="row checkbox-group-wrapper mt-5">
          {options.map((option, index) => {
            return (
              <>
                <div style={{ position: "relative" }}>
                  <Field
                    key={option.value}
                    name={name}
                    variant="checkbox"
                    component={EnsInput}
                    label={option.label}
                    value={option.value}
                    icon={option.icon}
                    checked={option.mandatory}
                    disabled={option.disabled || option.mandatory}
                    disabledMessage={option.disabledMessage}
                    className={`${selectedOption === index ? "selected" : ""}`}
                    onClick={() => setSelectedOption(index)}
                  />
                  {option.details && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "21px",
                        right: "20px",
                      }}
                    >
                      <InfoIcon
                        customStyle="blue-info-btn"
                        infoMessage={option.details}
                        imgColor="blue"
                        isToolTip={false}
                        tooltipPosition="left-start"
                      />
                    </div>
                  )}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

EnsCheckboxGroup.defaultProps = {
  values: [],
}

EnsCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.any,
  ]),
}

export default EnsCheckboxGroup
