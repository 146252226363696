import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import "./EnsRadioInput.scss"

const EnsRadioInput = ({
  variant,
  field,
  label,
  name,
  id,
  value,
  icon,
  form: { touched, errors, setFieldValue, handleBlur, values },
  className,
  item,
  ...custom
}) => {
  if (item.type === "select" && item.options && item.options.length) {
    if (values[field.name] !== value)
      return (
        <div className="input-wrapper">
          <label htmlFor={id} className={`radio-option ${className}`}>
            <input
              {...field} // eslint-disable-line react/jsx-props-no-spreading
              type="radio"
              id={id}
              className="radio-input"
              onChange={() => setFieldValue(field.name, value)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...custom}
            />
            {icon && <img src={icon} alt="ensys icon" className="no-pointer-events"/>}
            <p className={`${variant}-label ${icon && "has-icon"}`}>{label}</p>
          </label>
        </div>
      )
    return (
      <div className="input-wrapper">
        <div className={`radio-option ${className} select-input`}>
          {icon && <img src={icon} alt="ensys icon" className="no-pointer-events"/>}
          <input
            readOnly
            className="radio-input select-radio"
            type="radio"
            checked
          />
          <Select
            classNamePrefix="ens"
            id={id}
            name={field.name}
            onBlur={handleBlur(`${field.name}Selected`)}
            onChange={v => setFieldValue(`${field.name}Selected`, v)} // eslint-disable-line no-shadow
            options={item.options}
            placeholder={item.placeholder || "Selectează"}
            isRequired
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...custom}
          />
          {touched[`${field.name}Selected`] &&
          errors[`${field.name}Selected`] ? (
            <>
              <div className="break-new-line" />
              <p className="form-error">
                <em>{errors[field.name]}</em>
              </p>
            </>
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <div className="input-wrapper">
      <label htmlFor={id} className={`radio-option ${className}`}>
        <input
          {...field} // eslint-disable-line react/jsx-props-no-spreading
          type="radio"
          id={id}
          value={value}
          checked={field.value === value}
          className="radio-input"
          onChange={() => setFieldValue(field.name, value)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...custom}
        />
        {icon && <img src={icon} alt="ensys icon" className="no-pointer-events"/>}
        <p className={`${variant}-label ${icon && "has-icon"}`}>{label}</p>
      </label>
    </div>
  )
}

EnsRadioInput.defaultProps = {
  id: null,
  name: null,
  variant: "text",
  label: "",
  value: null,
  icon: null,
  className: "",
  item: {},
}

EnsRadioInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["text", "email", "select", "radio", "checkbox"]),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ]),
  }).isRequired,
  name: PropTypes.string,
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    values: PropTypes.shape({}),
  }).isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
    PropTypes.number,
  ]),
  icon: PropTypes.string,
  className: PropTypes.string,
  item: PropTypes.shape({
    options: PropTypes.array,
    placeholder: PropTypes.string,
    type: PropTypes.string,
  }),
}

export default EnsRadioInput
