export const counties = [
  { label: "Alba", value: "Alba", type: "classic" },
  { label: "Arad", value: "Arad", type: "classic" },
  { label: "Arges", value: "Arges", type: "classic" },
  { label: "Bacău", value: "Bacău", type: "classic" },
  { label: "Bihor", value: "Bihor", type: "classic" },
  { label: "Bistriţa-Năsăud", value: "Bistriţa-Năsăud", type: "classic" },
  { label: "Botoşani", value: "Botoşani", type: "classic" },
  { label: "Braşov", value: "Braşov", type: "classic" },
  { label: "Brăila", value: "Brăila", type: "classic" },
  { label: "Bucureşti", value: "Bucureşti", type: "classic" },
  { label: "Buzău", value: "Buzău", type: "classic" },
  { label: "Caraş-Severin", value: "Caraş-Severin", type: "classic" },
  { label: "Călăraşi", value: "Călăraşi", type: "classic" },
  { label: "Cluj", value: "Cluj", type: "classic" },
  { label: "Constanţa", value: "Constanţa", type: "classic" },
  { label: "Covasna", value: "Covasna", type: "classic" },
  { label: "Dâmboviţa", value: "Dâmboviţa", type: "classic" },
  { label: "Dolj", value: "Dolj", type: "classic" },
  { label: "Galaţi", value: "Galaţi", type: "classic" },
  { label: "Giurgiu", value: "Giurgiu", type: "classic" },
  { label: "Gorj", value: "Gorj", type: "classic" },
  { label: "Harghita", value: "Harghita", type: "classic" },
  { label: "Hunedoara", value: "Hunedoara", type: "classic" },
  { label: "Ialomiţa", value: "Ialomiţa", type: "classic" },
  { label: "Iaşi", value: "Iaşi", type: "classic" },
  { label: "Ilfov", value: "Ilfov", type: "classic" },
  { label: "Maramureş", value: "Maramureş", type: "classic" },
  { label: "Mehedinţi", value: "Mehedinţi", type: "classic" },
  { label: "Mureş", value: "Mureş", type: "classic" },
  { label: "Neamţ", value: "Neamţ", type: "classic" },
  { label: "Olt", value: "Olt", type: "classic" },
  { label: "Prahova", value: "Prahova", type: "classic" },
  { label: "Satu Mare", value: "Satu Mare", type: "classic" },
  { label: "Sălaj", value: "Sălaj", type: "classic" },
  { label: "Sibiu", value: "Sibiu", type: "classic" },
  { label: "Suceava", value: "Suceava", type: "classic" },
  { label: "Teleorman", value: "Teleorman", type: "classic" },
  { label: "Timiş", value: "Timiş", type: "classic" },
  { label: "Tulcea", value: "Tulcea", type: "classic" },
  { label: "Vâlcea", value: "Vâlcea", type: "classic" },
  { label: "Vaslui", value: "Vaslui", type: "classic" },
  { label: "Vrancea", value: "Vrancea", type: "classic" },
]
