/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import formConfig from "../../hooks/Configurator/formConfig"
import { useStore } from "../../hooks/Configurator/states"
import ConfiguratorForm from "../ConfiguratorForm/ConfiguratorForm"
import ConfiguratorFooter from "../ConfiguratorFooter/ConfiguratorFooter"
import EnsButton from "../EnsButton/EnsButton"
import ConfiguratorSummary from "../ConfiguratorSummary/ConfiguratorSummary"
import ConfiguratorFinalizeForm from "../ConfiguratorFinalizeForm/ConfiguratorFinalizeForm"
import ThankYouForSubmitting from "../ThankYouForSubmitting/ThankYouForSubmitting"
import "./Configurator.scss"

const Configurator = ({ setConfiguratorType, configuratorType }) => {
  const [finalize, setFinalize] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)
  const [csrfToken, setCsrfToken] = useState("")
  const { t } = useTranslation()
  const AfmFinancing = t("Programul AFM Casa Verde")
  //TODO remove conditional when .env notation works on prod
  const salesApiUrl = process.env.GATSBY_ENSYS_SALES_API ? `${process.env.GATSBY_ENSYS_SALES_API}` : 'https://sales.api.ensys.eu/api'

  if (!csrfToken) {
    setCsrfToken(
      fetch(salesApiUrl, {
        method: "GET",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          return data
        })
    )
  }

  const formConfigs = formConfig(configuratorType)

  // Store
  const formStore = useStore(state => state.formStore)
  // Actions
  const setFormStore = useStore(state => state.setFormStore)

  const finalizeConfig = () => {
    setFormStore({
      ...formStore,
      values: {
        projectType: configuratorType,
        ...formStore.values,
      },
    })
    if (!finalize) setFinalize(true)
    setConfiguratorType(configuratorType)
  }

  useEffect(() => {
    if (!formStore.currentQuestion) {
      setFormStore({
        ...formStore,
        currentQuestion: formConfigs[0].uid,
        currentItem: formConfigs[0],
        values: {},
      })
    }
  }, [formStore, setFormStore])

  // reset the formStore on first load
  useEffect(() => {
    setFormStore({
      ...formStore,
      currentQuestion: formConfigs[0].uid,
      currentItem: formConfigs[0],
      values: {},
    })
  }, [])

  /**
   * Filter configuration values so the data isn't saving ambiguous.
   * @param {Array} data - The array containing configuration values.
   * @returns {Array} - The filtered configuration values.
   */
  const filterConfigurationValues = data => {
    const fullOption = 'Sistem fotovoltaic + Acumulator + Statie de incarcare';
    const battery = 'Sistem fotovoltaic + Acumulator';
    const chargingStation = 'Sistem fotovoltaic + Statie de incarcare';
    const basicSystem = 'Sistem fotovoltaic';

    if (data.includes(fullOption)) {
      return [fullOption];
    } else if (data.includes(chargingStation) && data.includes(battery)) {
      return [fullOption];
    } else if (data.includes(battery) && !data.includes(chargingStation)) {
      return [battery];
    } else if (data.includes(chargingStation) && !data.includes(battery)) {
      return [chargingStation];
    } else {
      return [basicSystem];
    }
  };

  // Submit config form handler
  const handleConfigSubmit = values => {
    if (values) {
      if (values.configuration !== undefined)
        values.configuration = filterConfigurationValues(values.configuration)
      if (values.financingMethod?.value === AfmFinancing) {
        window.location.href =
          process.env.CASA_VERDE_LANDING_PAG ||
          "https://casaverde.ensys.eu/"
        return
      }
      const nextQuestionVariants = formConfigs.find(
        item => item.uid === formStore.currentQuestion
      ).nextQuestion
      const nextQuestionUid = nextQuestionVariants[0].questionUid

      const nextQuestion = formConfigs.find(
        item => item.uid === nextQuestionUid
      )
      setFormStore({
        ...formStore,
        btnLabel: t("Următorul pas"),
        currentQuestion: nextQuestionUid,
        currentItem: nextQuestion,
        values: { ...formStore.values, ...values },
      })
    }
  }

  // Submit config form handler
  const handleFinalizeSubmit = async values => {
    const csrf = await csrfToken
    const data = {
      projectType: formStore.values.projectType,
      quiz: {
        electricalSystem: formStore.values?.electricalSystem,
        configuration: formStore.values?.configuration,
        bridgingType: formStore.values?.bridgingType,
        amount: formStore.values?.amount,
        numberOfFloors: formStore.values?.numberOfFloors?.value,
        numberOfPeople: formStore.values?.numberOfPeople,
        timeOfDay: formStore.values?.timeOfDay,
        mountingType: formStore.values?.mountingType?.value
          ? formStore.values?.mountingType?.value
          : formStore.values?.mountingType,
        companyProfile: formStore.values?.companyProfile,
        batteriesOptIn: formStore.values?.batteriesOptIn,
        mountingSurface: formStore.values?.mountingSurface,
        kWh: formStore.values?.kWh,
        otherInfos: formStore.values?.otherInfos,
      },
      contact: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        typeOfContactId: values.typeOfContact,
        newsLetter: values.newsLetter,
        latitude: values.latitude,
        longitude: values.longitude,
        fullAddress: values.fullAddress,
        county: values.county.value,
      },
    }
    const configSaveUrl = `${process.env.GATSBY_ENSYS_SALES_API}/configurators/save` || 'https://localhost:3002/api/configurators/save'
    const ensysDB = await fetch(
      configSaveUrl,
      {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "csrf-token": csrf.csrfToken,
        },
        body: JSON.stringify(data),
      }
    )
      .then(response => {
        return response.status
      })
      .catch(response => {
        toast.error(response.message, {
          icon: false,
          theme: "colored",
          position: "top-left",
          autoClose: 7000,
          pauseOnHover: false,
          draggable: false,
          toastId: "contact-toast",
        })
      })

    if (values && ensysDB === 200) {
      const emailValues = {
        ...values,
        googleMapsURL: `http://www.google.com/maps/place/${values.latitude},${values.longitude}`,
      }
      axios
        .post("/api/send-email", {
          SentFrom: "Configurator Page",
          ...formStore.values,
          ...emailValues,
        })
        .then(response => {
          if (response.status === 200) {
            toast.success(t("Mesajul a fost trimis"), {
              icon: false,
              theme: "colored",
              position: "top-left",
              autoClose: 7000,
              pauseOnHover: false,
              draggable: false,
              toastId: "contact-toast",
            })
          }
        })
        .catch(() => {
          toast.error(t("Am întâmpinat o eroare la trimiterea mesajului"), {
            icon: false,
            theme: "colored",
            position: "top-left",
            autoClose: 7000,
            pauseOnHover: false,
            draggable: false,
            toastId: "contact-toast",
          })
        })

      setFormStore({
        ...formStore,
        values: { ...formStore.values, ...values },
      })

      if (!showThankYou) setShowThankYou(true)
    }
  }

  return (
    <div className="ensys-configurator">
      {formStore.currentItem && formStore.currentQuestion !== undefined ? (
        <div className="container">
          <ConfiguratorForm
            handleSubmit={handleConfigSubmit}
            formItem={formStore.currentItem}
            btnLabel={formStore.btnLabel}
          />
          <ConfiguratorFooter configuratorType={configuratorType} />
        </div>
      ) : !finalize ? (
        <div className="confirm-container">
          <ConfiguratorSummary
            open
            showToggle={false}
            configuratorType={configuratorType}
          />
          <h4>
            {t(
              "Dacă acest sumar reprezintă alegerea ta, te putem ajuta mai departe."
            )}
          </h4>
          <EnsButton
            variant="primary"
            className="finalize-button"
            onClick={finalizeConfig}
          >
            {t("Finalizează")}
          </EnsButton>
        </div>
      ) : !showThankYou ? (
        <ConfiguratorFinalizeForm
          handleSubmit={handleFinalizeSubmit}
          configuratorType={configuratorType}
          setConfiguratorType={setConfiguratorType}
        />
      ) : (
        <ThankYouForSubmitting />
      )}
    </div>
  )
}
Configurator.propTypes = {
  setConfiguratorType: PropTypes.func.isRequired,
  configuratorType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
}
export default Configurator
